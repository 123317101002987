import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: '[cnPasswordValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }]
})
export class PasswordValidator implements Validator {
    public validate(control: AbstractControl): ValidationErrors | null {
        return this.passwordValidator(control);
    }

    private passwordValidator(control: AbstractControl): ValidationErrors | null {
        const errors: { [key: string]: boolean } = {};

        if (control.value?.length < 6) {
            errors.length = true;
        } else if (!/[A-Za-z]/.test(control.value)) {
            errors.letter = true;
        } else if (!/[^a-zA-Z]/.test(control.value)) {
            errors.special = true;
        }

        return Object.keys(errors).length > 0 ? errors : null;
    }
}